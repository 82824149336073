import { Injectable } from '@angular/core';
import { Route, ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProfileGuard  {

  constructor(
    private router: Router
  ) {}
  
  private process(url: string): boolean {
    const profile = localStorage.getItem('profile');
    if (!profile) {
      return true;
    } else {
      switch (profile) {
        case 'SUPERADMIN':
          if (url.startsWith('/sales/new')) {
            this.router.navigateByUrl('/sales');
            return false;
          }
          return true;
        case 'RESELLER':
          if (url.startsWith('/home') || url.startsWith('/sales') || url.startsWith('/bonifici')) {
            if (url.startsWith('/sales/new')) {
              this.router.navigateByUrl('/sales');
              return false;
            }
            return true;
          } else {
            this.router.navigateByUrl('/home');
            return false;
          }
        case 'MANAGER':
          if (url.startsWith('/home') || url.startsWith('/sales') || url.startsWith('/profile-rate') ||
            url.startsWith('/user') || url.startsWith('/statistics') || url.startsWith('/bonifici') ||
            url.startsWith('/device') || url.startsWith('/faq')) {
            if (url.startsWith('/faq/new/') || url.startsWith('/faq/edit/')) {
              this.router.navigateByUrl('/faq');
              return false;
            }
            return true;
          } else {
            this.router.navigateByUrl('/home');
            return false;
          }
        case 'RESPONSIBLE':
          if (url.startsWith('/home') || url.startsWith('/sales') || url.startsWith('/user') ||
            url.startsWith('/device') || url.startsWith('/faq')) {
            if (url.startsWith('/faq/new/') || url.startsWith('/faq/edit/')) {
              this.router.navigateByUrl('/faq');
              return false;
            }
            return true;
          } else {
            this.router.navigateByUrl('/home');
            return false;
          }
        case 'AGENT':
          if (url.startsWith('/home') || url.startsWith('/sales') || url.startsWith('/device') ||
            url.startsWith('/faq')) {
            if (url.startsWith('/faq/new/') || url.startsWith('/faq/edit/')) {
              this.router.navigateByUrl('/faq');
              return false;
            }
            return true;
          } else {
            this.router.navigateByUrl('/home');
            return false;
          }
        default:
          return true;
      }
    }
  }

  // lazy loading
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.process(route.path);
  }
  // route
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.process(state.url);
  }
  // all children route
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.process(state.url);
  }
}
