<div class="wrapper is-flex flex-vertical bg-secondary">
    <!-- Main section-->
    <!-- <section class="section-container"> -->
    <section class="page-content">
        <!-- Page content-->
        <div class="content-wrapper">
            <router-outlet></router-outlet>
        </div>
        
    </section>
    <!-- Page footer-->

    <section class="lang">
        <app-lang-footer></app-lang-footer>
    </section>
   
</div>