/* src/app/routes/pages/payments/stripe/edit-method/edit-method.component.scss */
:host {
  height: 100%;
}
.logo-padded {
  max-width: 256px;
  min-height: 64px;
  object-fit: none;
}
.icon-row {
  justify-content: center;
}
.icon-row em {
  font-size: 30px !important;
}
.icon-row + p.text-danger {
  font-size: 20px;
  display: inline-block;
  max-width: 320px;
}
.stripe-field {
  height: 2.1875rem;
  padding: 0.375rem 1rem;
  border: 1px solid #dde6e9;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.stripe-field.StripeElement--focus {
  border-color: #66afe9;
}
.method-agreement-panel {
  color: #909FA7;
  font-size: 12px;
}
.card-disabled {
  background-color: #ddd;
  opacity: 0.5;
}
.card-disabled .c-radio * {
  cursor: auto;
}
.card-disabled .c-radio input[type=radio]:disabled + span {
  background-color: #ddd !important;
}
.form-control-plaintext-small {
  font-size: 7px;
  line-height: normal;
  text-transform: uppercase;
}
/*# sourceMappingURL=edit-method.component.css.map */
