/* src/app/layout/layout.component.scss */
@media (min-width: 768px) {
  .only-mobile {
    display: none;
  }
  .section-container {
    margin-left: 0px !important;
  }
}
@media (min-width: 768px) and (max-width: 1068px) {
  .pb-60 {
    padding-top: 120px !important;
    padding-bottom: 60px !important;
  }
}
@media (min-width: 1068px) {
  .pb-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .pb-60 {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
.fixPadding {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}
app-layout[_ngcontent-c1] > .wrapper,
.section-wrapper,
.section-wrapper > .content-wrapper {
  height: 100%;
}
app-insert-user,
app-layout router-outlet + app-insert-user {
  height: 100% !important;
}
app-footer {
  background-color: #FFFFFF;
}
/*# sourceMappingURL=layout.component.css.map */
