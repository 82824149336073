/* src/app/layout_blank/layout_blank.component.scss */
.lb {
  height: 100%;
  background-color: #fff;
}
.is-flex.flex-vertical {
  display: flex;
  flex-direction: column;
}
.page-content {
  flex: 1;
  height: auto;
  max-height: unset;
}
.wrapper > .lang {
  margin-bottom: 20px;
}
.page-content > .content-wrapper {
  height: 100%;
}
.wrapper {
  height: 100%;
}
.content-wrapper app-summary {
  height: 100%;
}
/*# sourceMappingURL=layout_blank.component.css.map */
