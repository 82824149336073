/* src/app/app.component.scss */
.user-image {
  display: inline;
  width: 25px;
}
.user-image img {
  margin: 15px 0;
  width: 25px;
}
.rounded-img {
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: #fff;
}
app-layout .wrapper {
  height: 100%;
}
/*# sourceMappingURL=app.component.css.map */
