/* src/app/layout/header/header.component.scss */
.menu-item a,
.logout {
  cursor: pointer;
}
.user-name {
  color: #efeef1;
  padding-right: 5px;
}
.m-0 {
  margin: 0px !important;
}
.logout {
  color: #46435d;
}
.divider {
  width: 1px;
  background: #efeef1;
  margin: 15px 0;
}
.icon-wrapper {
  position: relative;
  float: left;
}
.badge {
  background: #fbc16d;
  color: #fbc16d;
  width: 8px;
  height: 8px;
  margin: 0;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: -3px;
  padding: 3px;
  border: 2px solid #efeef1;
  font-size: 2px;
}
.bold {
  font-weight: 700;
}
.user-menu {
  border-radius: 0px !important;
  top: 48px !important;
  width: 177px;
  left: -32px !important;
}
.user-menu li {
  text-align: center;
}
.user-menu li a {
  color: #666666;
}
.user-menu:before {
  content: "";
  position: absolute;
  right: 37px;
  top: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9999;
}
@media (max-width: 767px) {
  .hide-mobile {
    display: none;
  }
  .flex-row.top-menu {
    flex-grow: 1;
  }
  .flex-row.top-menu.mr-auto {
    margin-right: unset !important;
  }
  .flex-row.top-menu .select-lang-wrapper {
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .only-mobile {
    display: none;
  }
  .section-container {
    margin-left: 0px !important;
  }
}
@media (min-width: 949px) and (max-width: 979px) {
  .topnavbar .navbar-header {
    width: 80px !important;
  }
}
@media (min-width: 768px) and (max-width: 1068px) {
  .top-menu {
    order: 2;
    margin-left: auto;
  }
}
.menu-item.active {
  border-bottom: 4px solid #fff;
  margin: 0 15px;
}
.menu-item.active a {
  color: #fff;
  padding-left: 0px;
  padding-right: 0px;
}
.menu-right {
  line-height: 3.7;
}
.logo {
  height: 40px;
  width: 80px;
  object-fit: contain;
}
.capitals-header {
  margin-top: 7px;
  display: inline-block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: #666666;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }
  .account-info {
    width: 345px;
    width: 360px;
  }
}
.order-first {
  order: 0 !important;
}
.account-info {
  justify-content: flex-end;
}
.m1 {
  margin: 0 1px;
}
.select-lang {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.select-lang ul {
  padding-left: 0;
}
.select-lang li {
  list-style: none;
  padding-left: 0;
  max-width: 35px;
  overflow: hidden;
  max-height: 35px;
}
.select-lang ul ul {
  display: none;
}
.current-lang img {
  max-width: 100%;
}
ul.lang-list {
  position: absolute;
}
.select-lang .lang-list li {
  max-width: unset;
  max-height: unset;
  text-align: center;
}
.select-lang .lang-list:before {
  content: "";
  position: absolute;
  right: 37px;
  top: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9999;
}
.select-lang .dropdown-toggle::after {
  vertical-align: middle;
  color: #efeef1;
}
.select-lang .user-menu.lang-list {
  top: 38px !important;
  width: 50px;
}
.select-lang .lang-list .lang-item {
  width: 50px;
}
.select-lang .dropdown-menu.lang-list {
  min-width: unset;
  right: unset;
  left: -10px !important;
}
.select-lang .arrow-center.user-menu:before {
  left: 0;
  right: 0;
  margin: auto;
}
.arrow-center.user-menu:before {
  left: 0;
  right: 0;
  margin: auto;
}
/*# sourceMappingURL=header.component.css.map */
