import { LayoutComponent } from '../layout/layout.component';
import { LayoutBlankComponent } from '../layout_blank/layout_blank.component';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { PaymentCodeComponent } from './pages/payment-code/payment-code.component';
import { TecComponent } from './pages/tec/tec.component';
import { NewSaleComponent } from './sales/new-sale/new-sale.component';
import { Routes } from "@angular/router";
import { EditMethodComponent } from './pages/payments/stripe/edit-method/edit-method.component';
import { ProfileGuard } from '../core/guard/profile.guard';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
      {
        path: 'user', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), data: {
          // Uses static text (Home)
          breadcrumb: 'User'
        },
        canActivate: [ProfileGuard]
      },
      { path: 'seller', loadChildren: () => import('./sellers/sellers.module').then(m => m.SellersModule), canActivate: [ProfileGuard] },
      { path: 'reseller', loadChildren: () => import('./resellers/resellers.module').then(m => m.ResellersModule), canActivate: [ProfileGuard] },
      { path: 'external', loadChildren: () => import('./external-apis/external-apis.module').then(m => m.ExternalApisModule), canActivate: [ProfileGuard] },
      //   { path: 'insert-user', loadChildren: './users/insert-user.module#InsertUserModule' },
      { path: 'profile-rate', loadChildren: () => import('./profile-rate/profile-rate.module').then(m => m.ProfileRateModule), canActivate: [ProfileGuard] },
      { path: 'sales', loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule), canActivate: [ProfileGuard] },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
      { path: 'configuration', loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule) },
      { path: 'statistics', loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule), canActivate: [ProfileGuard] },
      { path: 'bonifici', loadChildren: () => import('./bonifici/bonifici.module').then(m => m.BonificiModule), canActivate: [ProfileGuard] },
      { path: 'device', loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule), canActivate: [ProfileGuard] },
      { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule), canActivate: [ProfileGuard] },
    ]
  },
  {
    path: '',
    component: LayoutBlankComponent,
    children: [
      { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
      { path: 'login/:id', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
      { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
      { path: 'merchant/tos/:token', loadChildren: () => import('./pages/register-tos/register-tos.module').then(m => m.RegisterTosModule) },
      { path: 'recover', loadChildren: () => import('./pages/recover/recover.module').then(m => m.RecoverModule) },
      { path: 'activate/:id', loadChildren: () => import('./pages/activate/activate.module').then(m => m.ActivateModule) },
      { path: 'activate-sale/:token', loadChildren: () => import('./pages/activate-sale/activate-sale.module').then(m => m.ActivateSaleModule) },
      { path: 'sale/change/:token', loadChildren: () => import('./pages/change-sale/change-sale.module').then(m => m.ChangeSaleModule) },
      { path: 'first-instalment',  component: PaymentCodeComponent },
      { path: 'first-instalment/:token', loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule) },
      // { path: 'first-instalment', loadChildren: './pages/error404/error404.module#Error404Module' },
      { path: 'make-payment/:token', loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule) },
      { path: 'make-payment',  component: PaymentCodeComponent },
      { path: 'sale/change/pm/:token', component: EditMethodComponent, data: { card: true } },
      { path: 'lock', loadChildren: () => import('./pages/lock/lock.module').then(m => m.LockModule) },
      { path: 'tec', component: TecComponent },
      { path: '404', loadChildren: () => import('./pages/error404/error404.module').then(m => m.Error404Module) },
      { path: '500', loadChildren: () => import('./pages/error500/error500.module').then(m => m.Error500Module) },
      { path: 'maintenance', loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule) },
      { path: 'sales/compiled/:token' , component: NewSaleComponent, pathMatch: 'full' }
    ]
  },
  { path: 'change-password', component: ChangePasswordComponent },

  // Not found
  { path: '**', redirectTo: '/home' }

];
