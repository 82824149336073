<div class="wrapper">
  <div class="block-center login-form">
    <!-- START card-->
    <div class="card card-flat text-center">
      <div class="card-header text-center bg-secondary ">
        <a href="#">
          <img class="block-center rounded logo-padded" src="assets/img/service-pay-logo.png" alt="Image" />
        </a>
      </div>
      <div class="card-body width-450">
        <div class=" icon-row">
          <em *ngIf="(errorMessage ) && responseArrived" class="icon-close text-danger mr-2 fa-2x fa-lg"></em>
        </div>
        <p class="h3 text-center py-2 text-danger secondary-text-color" *ngIf="(errorMessage) && responseArrived">{{errorMessage}}</p>
        <p class="h3 text-center py-2 text-danger secondary-text-color" *ngIf="(errorMessage) && responseArrived">{{'user.sale-notactivatedcode'
          | translate}}</p>

          <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" class="full-width col-md-12">
            <div class="col-md-4 offset-md-4 mt-3">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 m-auto">
                    <p>{{'sale.title_code' | translate}}</p>
                    <div class="has-float-label">
                      <input class="custom-float-label-input text-center" type="text" placeholder=" " formControlName="code" id="code">
                      <button class="btn btn-success btn-block mt-2" type="submit" [disabled]="!profileForm.valid" >{{'sale.buy' | translate}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
      </div>
    </div>
    <!-- END card-->
  </div>
</div>
