/* src/app/layout_blank/lang-footer/lang-footer.component.scss */
ul .lang-item {
  max-width: 50px;
}
.footer_dropdown_wrapper {
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
}
.dropdown-toggle::after {
  vertical-align: middle;
}
.footer_dropdown_wrapper ul.dropdown-menu {
  max-width: 50px;
  min-width: unset;
}
.lang-item .nav-link {
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
}
.lang-item .nav-link img {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
}
.footer_dropdown_wrapper .dropdown-menu {
  left: -10px !important;
}
/*# sourceMappingURL=lang-footer.component.css.map */
