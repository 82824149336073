/* src/app/routes/pages/payment-code/payment-code.component.scss */
.logo-padded {
  max-width: 256px;
  min-height: 64px;
  object-fit: none;
}
.icon-row {
  justify-content: center;
}
.icon-row em {
  font-size: 30px !important;
}
.icon-row + p.text-danger {
  font-size: 20px;
  display: inline-block;
  max-width: 320px;
}
/*# sourceMappingURL=payment-code.component.css.map */
