<div class="wrapper">
  <div class="block-center login-form">
    <!-- START card-->
    <div class="card card-flat text-center">
      <div class="card-header text-center bg-secondary ">
        <a href="#">
          <img class="block-center rounded logo-padded" src="assets/img/service-pay-logo.png" alt="Image" />
        </a>
      </div>
    </div>
    <div *ngIf="(errorMessage || !activated) && responseArrived && !saleData" class="card-body width-450">
      <div class="icon-row">
        <em class="icon-close text-danger mr-2 fa-2x fa-lg"></em>
      </div>
      <p class="text-center py-2 text-danger secondary-text-color" *ngIf="(errorMessage || !activated) && responseArrived">{{'user.sale-notfound' | translate}}</p>
    </div>
    <!-- END card-->
    <div *ngIf="(activated) && responseArrived && !editPaymentMethodSuccess && saleData" class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5 mt-3">
        <div class="card bg-light">
          <div class="card-header text-center">
            <h4>{{'sale.modify_payment_method' | translate}}</h4>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <label class="col-sm-5 col-form-label"><b>{{'sale.merchant' | translate}}</b></label>
              <div class="col-sm-7">
                <span class="form-control-plaintext">{{saleData.merchant.companyName}}</span>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-5 col-form-label"><b>{{'sale.service_name' | translate}}</b></label>
              <div class="col-sm-7">
                <span class="form-control-plaintext">{{saleData.sale.service}}</span>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-5 col-form-label"><b>{{'sale.client_name' | translate}}</b></label>
              <div class="col-sm-7">
                <span class="form-control-plaintext">
                  <ng-container *ngIf="saleData.sale.fullName">{{saleData.sale.fullName}}</ng-container>
                  <ng-container *ngIf="!saleData.sale.fullName">{{saleData.sale.name}} {{saleData.sale.surname}}</ng-container>
                  <br />{{saleData.sale.email}}<br />
                  {{saleData.sale.phoneNumber}}
                </span>
              </div>
            </div>
            <hr>
            <div class="form-group row">
              <div class="card col-11 bg-secondary white m-auto">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <form (ngSubmit)="saveMethod()" #formSummaryStripe="ngForm">

                        <ng-container *ngIf="salePaymentMethods">
                          <ng-container *ngIf="salePaymentMethod">
                            <ng-container *ngIf="salePaymentMethodType == PaymentMethodType.CARD">
                              <div class="card">
                                <div class="col-12">
                                  <div class="row align-items-center">

                                    <div class="col-3">
                                      <img src="assets/img/payment-icons/{{salePaymentMethod.type}}.png" width="36"/>
                                      <img *ngIf="salePaymentMethod.country" src="assets/img/country/{{salePaymentMethod.country}}.png" width="24" class="ml-1"/>
                                    </div>
    
                                    <div class="col-4">
                                      <span class="form-control-plaintext"><small>xxxx-xxxx-xxxx-</small>{{salePaymentMethod.last4}}</span>
                                    </div>
    
                                    <div class="col-3">
                                      <span class="form-control-plaintext">{{salePaymentMethod.expMonth | number: '2.0-0'}} / {{salePaymentMethod.expYear}}</span>
                                    </div>

                                    <div class="col-2">
                                      <span class="form-control-plaintext form-control-plaintext-small">{{'sale.payment_method_card_current' | translate}}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="salePaymentMethodType == PaymentMethodType.SEPA_DEBIT">
                              <div class="card">
                                <div class="col-12">
                                  <div class="row align-items-center">
            
                                    <div class="col-3">
                                      <img src="assets/img/payment-icons/sepa.png" width="36"/>
                                      <img *ngIf="salePaymentMethod.country" src="assets/img/country/{{salePaymentMethod.country}}.png" width="24" class="ml-1"/>
                                    </div>
            
                                    <div class="col-4">
                                      <span class="form-control-plaintext"><small>xxxx-xxxx-xxxx-</small>{{salePaymentMethod.last4}}</span>
                                    </div>
            
                                    <div class="col-3">
                                    </div>
            
                                    <div class="col-2">
                                      <span class="form-control-plaintext form-control-plaintext-small">{{'sale.payment_method_sepa_current' | translate}}</span>
                                    </div>
            
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <div class="mt-3"></div>

                          </ng-container>

                          <div class="card" *ngFor="let card of salePaymentMethods.cards; let i = index;" (click)="(!card.expired && card.available) && rowPaymentMethodSelected(card, PaymentMethodType.CARD)"
                            [ngClass]="{'pointer': (!card.expired && card.available), 'card-disabled': (card.expired || !card.available)}" [hidden]="card.current">
                            <div class="col-12">
                              <div class="row align-items-center">

                                <div class="col-3">
                                  <img src="assets/img/payment-icons/{{card.type}}.png" width="36"/>
                                  <img *ngIf="card.country" src="assets/img/country/{{card.country}}.png" width="24" class="ml-1"/>
                                </div>

                                <div class="col-4">
                                  <span class="form-control-plaintext"><small>xxxx-xxxx-xxxx-</small>{{card.last4}}</span>
                                </div>

                                <div class="col-3">
                                  <span class="form-control-plaintext">{{card.expMonth | number: '2.0-0'}} / {{card.expYear}}</span>
                                </div>

                                <div class="col-2">
                                  <div class="c-radio float-right" *ngIf="!card.expired && card.available">
                                    <label class="mb-0" for="card_{{i}}">
                                      <input type="radio" name="card_{{i}}" id="card_{{i}}" [checked]="isPaymentMethodSelected(card)"
                                        [disabled]="card.expired || !card.available" />
                                      <span class="fa fa-check"></span>
                                    </label>
                                  </div>
                                  <span class="form-control-plaintext form-control-plaintext-small" *ngIf="card.expired || !card.available">
                                    <ng-container *ngIf="card.expired">{{'sale.payment_method_card_expired' | translate}}</ng-container>
                                    <ng-container *ngIf="!card.expired && !card.available">{{'sale.payment_method_card_not_supported' | translate}}</ng-container>
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>

                          <ng-container *ngIf="isPaymentMethodAvailable(PaymentMethodType.SEPA_DEBIT)">
                            <div class="card" *ngFor="let sepa of salePaymentMethods.sepas; let i = index;" (click)="sepa.available && rowPaymentMethodSelected(sepa, PaymentMethodType.SEPA_DEBIT)"
                              [ngClass]="{'pointer': sepa.available, 'card-disabled': !sepa.available}" [hidden]="sepa.current">
                              <div class="col-12">
                                <div class="row align-items-center">
          
                                  <div class="col-3">
                                    <img src="assets/img/payment-icons/sepa.png" width="36"/>
                                    <img *ngIf="sepa.country" src="assets/img/country/{{sepa.country}}.png" width="24" class="ml-1"/>
                                  </div>
          
                                  <div class="col-4">
                                    <span class="form-control-plaintext"><small>xxxx-xxxx-xxxx-</small>{{sepa.last4}}</span>
                                  </div>
          
                                  <div class="col-3">
                                  </div>
          
                                  <div class="col-2">
                                    <div class="c-radio float-right" *ngIf="sepa.available">
                                      <label class="mb-0" for="sepa_{{i}}">
                                        <input type="radio" name="sepa_{{i}}" id="sepa_{{i}}" [checked]="isPaymentMethodSelected(sepa)"
                                          [disabled]="!sepa.available" />
                                        <span class="fa fa-check"></span>
                                      </label>
                                    </div>
                                    <span class="form-control-plaintext form-control-plaintext-small" *ngIf="!sepa.available">{{'sale.payment_method_sepa_not_supported' | translate}}</span>
                                  </div>
          
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>

                        <div class="card" (click)="rowNewPaymentMethodSelected()">
                          <div class="col-12">
                            <div class="row align-items-center my-2" *ngIf="existOtherPaymentMethod()">
      
                              <div class="col-10">
                              </div>
      
                              <div class="col-2">
                                <div class="c-radio float-right">
                                  <label class="mb-0" for="new_{{i}}">
                                    <input type="radio" name="new_{{i}}" id="new_{{i}}" [checked]="isNewPaymentMethodSelected()" />
                                    <span class="fa fa-check"></span>
                                  </label>
                                </div>
                              </div>
                            
                            </div>

                            <div class="mt-4" *ngIf="!existOtherPaymentMethod()"></div>
            
                            <app-stripe-element [configuration]="configurationSettings" [mode]="'manage'" [paymentData]="saleData" [availablePaymentMethods]="availablePaymentMethods" (onValidationChange)="getStripeValidation($event)"></app-stripe-element>
                            
                            <!--
                              <div class="form-group row">
                                <div class="col-12 d-inline_block">
                                  <h4>{{'sale.info_tec' | translate}}</h4>
                                  <div class="checkbox c-checkbox mt0 d-flex">
                                    <label for="tec">
                                      <input type="checkbox" name="tec" id="tec" tec #tec (change)="changeTeC()"/>
                                      <span class="fa fa-check"></span>
                                    </label>
                                    <div class="d-inline_block" [innerHTML]="'sale.tec' | translate"></div>
                                  </div>
                                </div
                              </div>
                              
                              <div class="form-group row">
                                <div class="col-12 d-inline_block">
                                  <h4>{{'sale.info_gdpr' | translate}}</h4>
                                  <div class="checkbox c-checkbox mt0 d-flex">
                                    <label for="gdpr">
                                      <input type="checkbox" name="gdpr" id="gdpr" gdpr #gdpr (change)="changeGDPR()"/>
                                      <span class="fa fa-check"></span>
                                    </label>
                                    <div class="d-inline_block" [innerHTML]="'sale.gdpr' | translate"></div>
                                  </div>
                                </div>
                              </div>
                            -->

                          </div>
                        </div>
                        
                        <!--
                          <button class="btn btn-primary btn-block" type="submit" [disabled]="isTec==0 || isGDPR==0 || stripeValid == 0 || saving">
                            <span >{{'sale.btn_edit_payment_method' | translate}}</span>
                          </button>
                        -->
                        <button type="submit" class="btn btn-primary btn-block" [disabled]="(!selectedPaymentMethod && stripeValid == 0) || saving">
                          <span >{{'sale.btn_edit_payment_method' | translate}}</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="(activated) && responseArrived && editPaymentMethodSuccess && saleData" class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card bg-light">
          <div class="card-header text-center">
            <h4>{{'sale.summary_editmethod_title' | translate}}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p >
                  {{'sale.summary_editmethod_text_1' | translate}} <br>
                  {{'sale.summary_editmethod_text_2' | translate}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

