@charset "UTF-8";

/* node_modules/@swimlane/ngx-datatable/index.css */
.ngx-datatable {
  display: block;
  overflow: hidden;
  justify-content: center;
  position: relative;
  transform: translate3d(0, 0, 0);
}
.ngx-datatable [hidden] {
  display: none !important;
}
.ngx-datatable *,
.ngx-datatable *:before,
.ngx-datatable *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ngx-datatable.scroll-vertical .datatable-body {
  overflow-y: auto;
}
.ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
  position: absolute;
}
.ngx-datatable.scroll-horz .datatable-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
  white-space: nowrap;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ngx-datatable.fixed-row .datatable-scroll {
  white-space: nowrap;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
  white-space: nowrap;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ngx-datatable .datatable-body-row,
.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-header-inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  -webkit-flex-flow: row;
  -moz-flex-flow: row;
  -ms-flex-flow: row;
  -o-flex-flow: row;
  flex-flow: row;
}
.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  overflow-x: hidden;
  vertical-align: top;
  display: inline-block;
  line-height: 1.625;
}
.ngx-datatable .datatable-body-cell:focus,
.ngx-datatable .datatable-header-cell:focus {
  outline: none;
}
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-right {
  z-index: 9;
}
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-row-group,
.ngx-datatable .datatable-row-right {
  position: relative;
}
.ngx-datatable .datatable-header {
  display: block;
  overflow: hidden;
}
.ngx-datatable .datatable-header .datatable-header-inner {
  align-items: stretch;
  -webkit-align-items: stretch;
}
.ngx-datatable .datatable-header .datatable-header-cell {
  position: relative;
  display: inline-block;
}
.ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
  cursor: pointer;
}
.ngx-datatable .datatable-header .datatable-header-cell.longpress .datatable-header-cell-wrapper {
  cursor: move;
}
.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  line-height: 100%;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  padding: 0 4px;
  visibility: hidden;
}
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
  cursor: ew-resize;
}
.ngx-datatable .datatable-header .datatable-header-cell.resizeable:hover .resize-handle {
  visibility: visible;
}
.ngx-datatable .datatable-header .datatable-header-cell:hover .resize-handle--not-resizable {
  visibility: visible;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker {
  position: absolute;
  top: 0;
  bottom: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromLeft {
  right: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromRight {
  left: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  height: inherit;
}
.ngx-datatable .datatable-body {
  position: relative;
  z-index: 10;
  display: block;
}
.ngx-datatable .datatable-body .datatable-scroll {
  display: inline-block;
}
.ngx-datatable .datatable-body .datatable-row-detail {
  overflow-y: hidden;
}
.ngx-datatable .datatable-body .datatable-row-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ngx-datatable .datatable-body .datatable-body-row {
  outline: none;
}
.ngx-datatable .datatable-body .datatable-body-row > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.ngx-datatable .datatable-footer {
  display: block;
  width: 100%;
  overflow: auto;
}
.ngx-datatable .datatable-footer .datatable-footer-inner {
  display: flex;
  align-items: center;
  width: 100%;
}
.ngx-datatable .datatable-footer .selected-count .page-count {
  flex: 1 1 40%;
}
.ngx-datatable .datatable-footer .selected-count .datatable-pager {
  flex: 1 1 60%;
}
.ngx-datatable .datatable-footer .page-count {
  flex: 1 1 20%;
}
.ngx-datatable .datatable-footer .datatable-pager {
  flex: 1 1 80%;
  text-align: right;
}
.ngx-datatable .datatable-footer .datatable-pager .pager,
.ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li,
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  outline: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  cursor: pointer;
  display: inline-block;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
  cursor: not-allowed;
}

/* node_modules/@swimlane/ngx-datatable/assets/icons.css */
@font-face {
  font-family: "data-table";
  src: url("./media/data-table.eot");
  src:
    url("./media/data-table.eot?#iefix") format("embedded-opentype"),
    url("./media/data-table.woff") format("woff"),
    url("./media/data-table.ttf") format("truetype"),
    url("./media/data-table.svg#data-table") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]::before {
  font-family: "data-table" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^=datatable-icon-]::before,
[class*=" datatable-icon-"]::before {
  font-family: "data-table" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.datatable-icon-filter::before {
  content: "b";
}
.datatable-icon-collapse::before {
  content: "a";
}
.datatable-icon-expand::before {
  content: "c";
}
.datatable-icon-close::before {
  content: "d";
}
.datatable-icon-up::before {
  content: "e";
}
.datatable-icon-down::before {
  content: "f";
}
.datatable-icon-sort-unset::before {
  content: "c";
  opacity: 0.5;
}
.datatable-icon-sort::before {
  content: "g";
}
.datatable-icon-done::before {
  content: "h";
}
.datatable-icon-done-all::before {
  content: "i";
}
.datatable-icon-search::before {
  content: "j";
}
.datatable-icon-pin::before {
  content: "k";
}
.datatable-icon-add::before {
  content: "m";
}
.datatable-icon-left::before {
  content: "o";
}
.datatable-icon-right::before {
  content: "p";
}
.datatable-icon-skip::before {
  content: "q";
}
.datatable-icon-prev::before {
  content: "r";
}

/* src/app/routes/pages/tec/tec.component.scss */
.info {
  box-sizing: border-box;
  padding: 0 1em;
  border: solid 1px #ccc;
  background: white;
  font-size: 0.8em;
  margin-bottom: 1em;
}
.head {
  margin: -20px;
  margin-bottom: 20px;
  padding: 20px;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.1;
  color: #929292;
  padding-bottom: 70px;
  text-align: left;
  width: 75%;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 0px;
}
.font-size {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
}
.title {
  padding-left: 30px;
  padding-top: 25px;
  font-size: 17px;
  font-weight: bold;
  color: #5c5e69;
}
.head-title {
  color: #787a84;
}
.expander-btn {
  margin-top: 8px;
  display: block;
}
.selection-cell .datatable-body-cell.active {
  background: #0829e0 !important;
  color: #fff !important;
}
.selected-column {
  float: right;
  width: 20%;
  margin-right: 20px;
  text-align: left;
}
.selected-column h4 {
  text-align: center;
  margin: 10px 0 0 0;
  padding: 0;
}
.selection-cell .datatable-body-cell.active {
  background: #0829e0 !important;
  color: #fff !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}
.dropdown-toggle.blue-bg {
  border-radius: 4px;
  cursor: pointer;
  height: 32px;
}
.dropdown-toggle.blue-bg::after {
  margin-left: -2px;
}
.dropdown-toggle.blue-bg:hover::after {
  color: #ffffff !important;
}
.user-menu.show {
  display: block;
  margin-top: 5px;
}
.edit {
  color: #666666;
  padding: 3px 10px;
}
.data-table .h4 {
  color: #46435d;
}
.ngx-datatable .datatable-body {
  overflow: hidden;
}
.checkbox-s {
  line-height: 3;
}
.list-item {
  border: none;
}
.inner-panel {
  padding-right: 25px;
}
.white {
  background-color: white;
}
.app-search {
  justify-content: center;
}
.shadow {
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.w800 {
  width: 100% !important;
}
@media (min-width: 768px) and (max-width: 1024px) {
  ngx-datatable {
    width: 100% !important;
  }
  .moredd::after {
    text-align: center;
  }
}
.capitals {
  display: inline-block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: #666666;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
  text-transform: uppercase;
}
.datatable-row-detail {
  background-color: white !important;
}
.secondary-text-color {
  color: #a5a6ac;
  font-size: 13px;
}
.detail-dropdrown {
  padding-left: 10px;
  width: 197px;
}
/*# sourceMappingURL=tec.component.css.map */
